<template>
  <div class="bgimg">
    <div class="login">
      <div class="login-form">
        <h3 class="title">不断电移动端管理系统</h3>
        <div>
          <nut-input v-model="loginForm.username" placeholder="账号" clearable>
            <template #left> <IconFont font-class-name="iconfont" class-prefix="icon" name="yonghuming" size="25px"></IconFont> </template>
          </nut-input>
        </div>
        <div>
          <nut-input v-model="loginForm.password" placeholder="密码" type="password" clearable>
            <template #left> <IconFont font-class-name="iconfont" class-prefix="icon" name="mima" size="25px"></IconFont> </template>
          </nut-input>
        </div>
        <div v-if="captchaEnabled">
          <nut-input v-model="loginForm.code" placeholder="验证码" clearable>
            <template #left> <IconFont font-class-name="iconfont" class-prefix="icon" name="yanzhengma" size="25px"></IconFont></template>
            <template #right>
              <nut-image
                :src="codeUrl"
                @click="getCode"
                width="100"
                height="40"
              ></nut-image>
            </template>
          </nut-input>
        </div>
        <div style="width:100%;">
          <nut-button type="primary" :loading="loading" @click="handleLogin" style="width: 300px;margin-top:30px;margin-bottom: 10px;">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </nut-button>
        </div>
      </div>
          <div class="el-login-footer">
            <span>备案号<a href="http://beian.miit.gov.cn" target="_blank" style="color: white">宁ICP备2023001042号-1</a></span>
          </div>
    </div>
  </div>

</template>

<script>
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'
import { IconFont } from '@nutui/icons-vue'

export default {
  components: {
    IconFont
  },
  name: "Login",
  data() {
    return {
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
          this.loading = true;
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push({ path: "/index/content" }).catch(()=>{});
          }).catch(() => {
            this.loading = false;
            if (this.captchaEnabled) {
              this.getCode();
            }
          });

    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.bgimg{
  background-image: url("../assets/images/login-background.jpg");
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  width: 98%;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  height: 100%;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
}
</style>
